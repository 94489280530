import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormAction } from '../../../../contexts/FormActionContext';
import { isEmailValid } from '../../../../utils/EmailUtils';
import { Input } from '../../../shared/form-control/Input';
import ActionBaseProps from '../ActionBaseProps';
import ActionTitleDescription from '../ActionTitleDescription';

type EmailInputActionProps = ActionBaseProps<string>;

const EmailInputAction: FC<EmailInputActionProps> = (props) => {
  const { t } = useTranslation('activity-type');
  const { response, data, required } = props;
  const { onAnswer, onValid, readOnly } = useFormAction(props);

  const [value, setValue] = useState(response || '');
  const [isValid, setIsValid] = useState(true);
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    const newValue = response || '';
    setValue(newValue);
    if (newValue) {
      setIsDirty(true);
    }
  }, [response]);

  const validate = useCallback(
    (value: string) => {
      let isValid = true;
      if (!required && value.length === 0) {
        isValid = true;
      } else {
        isValid = isEmailValid(value);
      }

      return isValid;
    },
    [required],
  );

  useEffect(() => {
    const isValid = validate(response || '');
    setIsValid(isValid);
    onValid(isValid);
  }, [onValid, validate, response]);

  const onBlur = (value: string) => {
    const isValid = validate(value);
    setIsValid(isValid);
    onValid(isValid);
    onAnswer(value);
    setIsDirty(!!value);
  };

  return (
    <div className="flex flex-col" data-cy="email-input-action">
      <ActionTitleDescription required={required} {...data} />
      <Input
        type="email"
        className={!isValid ? 'border-red-500' : ''}
        value={value}
        onChange={(event) => setValue(event.target.value)}
        onBlur={() => onBlur(value)}
        placeholder={t('email-input.placeholder')}
        disabled={readOnly}
      />
      {isDirty && !isValid && <p className="text-dpm-14 py-1 text-red-500">{t('email-input.invalid')}</p>}
    </div>
  );
};

export default EmailInputAction;
